import { Button, Form, Tabs } from "antd";
import React, { useEffect } from "react";

import { IBanner, IBannerEdit } from "../../../models/banner.model";
import PropertiesBanners from "../properties-banner/PropertiesBanners";

import SelectBanner from "./select-banner/SelectBanner";

import "./BannerEdit.scss";

const { TabPane } = Tabs;
interface IBannerEditProps {
    isEdit: boolean,
    onCancel(): void,
    onOk(banner: IBanner, isEdit: boolean): void,
    loading: boolean,
    banner: IBanner,
    form: any;
};

const initialState  = {
    properties_pt: {
        title: "",
        thumbnailUrl: "",
        link: "",
    },
    properties_es: {
        title: "",
        thumbnailUrl: "",
        link: "",
    },
    properties_en: {
        title: "",
        thumbnailUrl: "",
        link: "",
    },
};

const BannerEdit = ({isEdit, onCancel, onOk, banner, loading, form}: IBannerEditProps) => {
    const {setFieldsValue, validateFields, getFieldsValue} = form;

    useEffect(() => {
        setFieldsValue({...initialState, ...bannerToBannerEdit(banner)})
    // eslint-disable-next-line
    }, []);

    const bannerEdit = {...initialState, ...bannerToBannerEdit(banner), ...getFieldsValue()}

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        validateFields((err: any, values: IBannerEdit) => {
          if (!err) {
            let item = {...bannerEdit, ...values};

            const propertiesEn = values["properties_en"];
            const propertiesEs = values["properties_es"];

            if (propertiesEn && propertiesEn.title === '') {
                item = {...item, properties_en: initialState.properties_en};
            }

            if (propertiesEs && propertiesEs.title === '') {
                item = {...item, properties_es: initialState.properties_es};
            }

            onOk(bannerEditToBanner(item), isEdit);
        }
    })};

    return (
        <Form
            onSubmit={handleSubmit}
            className='banner-edit'
            layout="vertical"
            style={{ maxWidth: 700, margin: "0 auto" }}
        >
            <Tabs defaultActiveKey="1">
                <TabPane
                    key="1"
                    tab={<span>Banner</span>}
                    forceRender
                >
                    <SelectBanner
                        isEdit={isEdit}
                        currentEdit={bannerEdit}
                        setCurrentEdit={setFieldsValue}
                        form={form}
                    />
                </TabPane>

                <TabPane
                    key="2"
                    tab={<span>Propriedades PT</span>}
                    forceRender
                >
                    <PropertiesBanners
                        isEdit={isEdit}
                        types="properties_pt"
                        currentEdit={bannerEdit}
                        setCurrentEdit={setFieldsValue}
                        form={form}
                    />
                </TabPane>

                <TabPane
                    key="3"
                    tab={<span>Propriedades ES</span>}
                    forceRender
                >
                    <PropertiesBanners
                        isEdit={isEdit}
                        types="properties_es"
                        currentEdit={bannerEdit}
                        setCurrentEdit={setFieldsValue}
                        form={form}
                    />
                </TabPane>

                <TabPane
                    key="4"
                    tab={<span>Propriedades EN</span>}
                    forceRender
                >
                    <PropertiesBanners
                        isEdit={isEdit}
                        types="properties_en"
                        currentEdit={bannerEdit}
                        setCurrentEdit={setFieldsValue}
                        form={form}
                    />
                </TabPane>
            </Tabs>

            <div className="buttons-bottom">
                <Button
                    className="btn-cancel"
                    onClick={onCancel}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={loading}
                    type="primary"
                    htmlType="submit"
                >
                    {loading ? "Salvando..." : "Salvar"}
                </Button>
            </div>
        </Form>
    );
};

function bannerToBannerEdit(banner: IBanner): IBannerEdit {
    const {
        titlePt,
        titleEn,
        titleEs,
        imageUrlPt,
        imageUrlEn,
        imageUrlEs,
        linkEn,
        linkEs,
        linkPt,
        ...rest
    } = banner;
    const bannerModalFormat = {
        ...rest,
        properties_pt: {
            title: titlePt,
            thumbnailUrl: imageUrlPt,
            link: linkPt,
        },
        properties_es: {
            title: titleEs,
            thumbnailUrl: imageUrlEs,
            link: linkEs,
        },
        properties_en: {
            title: titleEn,
            thumbnailUrl: imageUrlEn,
            link: linkEn,
        },
    }

    return bannerModalFormat;
}

function bannerEditToBanner(bannerModal: IBannerEdit): IBanner {
    const {
        properties_pt,
        properties_es,
        properties_en,
        ...rest
    } = bannerModal;

    const banner = {
        ...rest,
            titlePt: properties_pt.title,
            imageUrlPt: properties_pt.thumbnailUrl,
            linkPt: properties_pt.link,
            titleEs: properties_es && properties_es.title,
            imageUrlEs: properties_es && properties_es.thumbnailUrl,
            linkEs: properties_es && properties_es.link,
            titleEn: properties_en && properties_en.title,
            imageUrlEn: properties_en && properties_en.thumbnailUrl,
            linkEn: properties_en && properties_en.link,
    }

    return banner;
}

export default Form.create<IBannerEditProps>({ name: "banner_edit_form" })(BannerEdit);
