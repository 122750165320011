import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {IState} from "../reducers/reducers";
import handleError from "../utils/handleError";
import {BannersActionTypes, setBannerModal, setBanners, setLoading, setSaveLoading, setPageList} from "../reducers/banners.reducer";
import {IBanner, queryStringListBanners} from "../models/banner.model";
import {createBanner as _createBanner, listBanners, updateBanner} from "../utils/webApi";
import Notification, { NOTIFICATION_TYPE } from "../components/notification/Notification";
import { removePropsFromObject } from "../utils/utils.functions";

type IBannerAction = {
    payload: IBanner
    type: string,
};

function* createBanner({payload} : IBannerAction) {
    try {
        yield put(setSaveLoading(true));

        yield call(_createBanner, payload as IBanner);
        yield getBanners();
        yield put(setBannerModal(null));

        Notification(NOTIFICATION_TYPE.SUCCESS, 'Atenção', 'Banner criado com sucesso!');
    } catch (err) {
        handleError(err);
    } finally {
        yield put(setSaveLoading(false));
    }
}

function* getBanners() {
    try {
        yield put(setLoading(true));
        const {pageSize, search, pageList, page, orderBy} = yield select((state: IState) => state.banners);
        const params = {pageSize, search, orderBy, nextPage: pageList[page]};

        const {nextPage, items} = yield call(listBanners, queryStringListBanners(params));

        const newPageList = [...pageList];

        if (nextPage) {
            newPageList.push(nextPage);
            yield put(setPageList(newPageList));
        } else if (page === 0 && !nextPage) {
            yield put(setPageList([]));
        } else {
            newPageList.push(null);
            yield put(setPageList(newPageList));
        }

        yield put(setBanners(items));
    } catch (err) {
        handleError(err);
    } finally {
        yield put(setLoading(false));
    }
}

function* editBanner({payload} : IBannerAction) {
    try {
        yield put(setSaveLoading(true));

        const propsToRemove = ['createdAt', 'updatedAt', 'entity', 'status', 'search', 'key'] as const;
        const {id, isSwitchChange, ...editPayload} = removePropsFromObject(payload, propsToRemove);

        yield call(updateBanner, id, editPayload);
        yield getBanners();
        yield put(setBannerModal(null));

        if(isSwitchChange) {
            if(payload.isEnabled) {
                Notification(NOTIFICATION_TYPE.SUCCESS, 'Atenção', 'Banner habilitado com sucesso!');
            } else {
                Notification(NOTIFICATION_TYPE.SUCCESS, 'Atenção', 'Banner desabilitado com sucesso!');
            }
        } else {
            Notification(NOTIFICATION_TYPE.SUCCESS, 'Atenção', 'Banner editado com sucesso!');
        }

    } catch (err) {
        handleError(err);
    } finally {
        yield put(setSaveLoading(false));
    }
}

export default function* MySaga(): any {
    yield all([
        yield takeEvery(BannersActionTypes.ASYNC_CREATE_BANNER, createBanner),
        yield takeEvery(BannersActionTypes.ASYNC_GET_BANNERS, getBanners),
        yield takeEvery(BannersActionTypes.ASYNC_EDIT_BANNER, editBanner),
    ])
}
