import {Button, Modal} from "antd";
import React from "react";
import {useDispatch, useSelector} from "react-redux";

import TitleSection from "../../components/title-section/TitleSection";
import {IState} from "../../reducers/reducers";
import BannersList from "./videos-list/BannersList";
import {IBanner} from "../../models/banner.model";
import {
    asyncGetBanners,
    setBannerModal,
    asyncEditBanner,
    asyncCreateBanner,
} from "../../reducers/banners.reducer";

import BannerEdit from "./banner-edit/BannerEdit";

function Banners(): JSX.Element {
    const dispatch = useDispatch();
    const {
        bannerModal,
        isLoadingSave,
        isLoading,
        banners
    } = useSelector((state: IState) => state.banners);

    function onSaveBanner(banner: IBanner, isEdit: boolean) {
        if (isEdit) {
            dispatch(asyncEditBanner(banner));
        } else {
            dispatch(asyncCreateBanner(banner));
        }
    };

    function onCancel(){
        dispatch(setBannerModal(null));
    };

    return (
        <section>
            <TitleSection title="Banners">
                <Button
                    onClick={() => dispatch(setBannerModal({}))}
                    size="large"
                    type="primary"
                >
                    Adicionar Banner
                </Button>
            </TitleSection>
            <BannersList
                onEdit={(banner: IBanner) => dispatch(setBannerModal(banner))}
                getBanners={() => dispatch(asyncGetBanners())}
                banners={banners}
                loading={isLoading}
            />
            {bannerModal && (
                <Modal
                    width={700}
                    title={bannerModal.id ? "Editar Banner" : "Adicionar Banner"}
                    visible={true}
                    footer={false}
                    maskClosable={false}
                    onCancel={onCancel}
                >
                    <BannerEdit
                        isEdit={Boolean(bannerModal.id)}
                        onCancel={onCancel}
                        onOk={onSaveBanner}
                        banner={bannerModal as IBanner}
                        loading={isLoadingSave}
                    />
                </Modal>
            )}
        </section>
    );
};

export default Banners;
