import { all, takeEvery, put, call, select} from "redux-saga/effects";
import handleError from "../utils/handleError";
import {
    createLoosePayments,
    generateLoosePaymentCode,
    getCoachesForEmail,
    listLoosePayments,
    loosePaymentsFilter,
    loosePaymentsFilterDownloadXlsx,
    resendLoosePaymentEmail
} from "../utils/webApi";
import {
    asyncGetFilterPayment,
    clearStates,
    LoosePaymentActionTypes,
    setCoach,
    setCode,
    setIsLoading,
    setLoadingCoach,
    setLoadingCode,
    setLoadingReport,
    setLoadingSave,
    setLoosePayments,
    setPage,
    setTotal,
    updatePaymentStatus
} from "../reducers/loose-payment.reducer";
import { IState } from "../reducers/reducers";
import Notification, { NOTIFICATION_TYPE } from "../components/notification/Notification";

function* requestLoosePayments({ currentPage, pageSize }: any) {
    try {
        yield put(setIsLoading(true));

        const data = yield call(listLoosePayments, currentPage, pageSize);
        yield put(setLoosePayments(data.payments));
        yield put(setTotal(data.total));

    } catch (error) {
        handleError(error);
    } finally {
        yield put(setIsLoading(false));
    };
};

function* requestGetCoachByEmail({ payload }: any) {
    try {
        yield put(setLoadingCoach(true));

        const coach = yield call(getCoachesForEmail, payload);

        if(coach.coach){
            yield put(setCoach(coach));
        } else {
            handleError({message: 'Coach não encontrado'});
            yield put(clearStates());
        }

    } catch (error) {
        handleError(error);
        yield put(clearStates());
    } finally {
        yield put(setLoadingCoach(false));
    };
};

function* requestPostLoosePayment({ payload }: any) {
    let { pageSize, startDate, endDate } = yield select((state: IState) => state.loosePayments);
    try {
        yield put(setLoadingSave(true));
        payload.date_expiration = new Date(payload.date_expiration).toISOString();
        yield call(createLoosePayments, payload);

    } catch (error) {
        handleError(error);
    }finally {
        yield put(setLoadingSave(false));
        yield put(asyncGetFilterPayment({page: 1, pageSize, startDate, endDate}));
        yield put(setPage(1));
    };
};

function* requestGetCode() {
    try {
        yield put(setLoadingCode(true));

        const code = yield call(generateLoosePaymentCode);
        yield put(setCode(code.id))
    } catch (error) {
        handleError(error);
    } finally {
        yield put(setLoadingCode(false));
    }
};

function* requestLoosePaymentsFilter({ payload }: any) {
    const { page, pageSize } = yield select(({ loosePayments }: IState) => loosePayments);
    const { name, email, type, status, startDate, endDate } = payload;
    try {
        yield put(setIsLoading(true));
        const data = yield call(loosePaymentsFilter, page, pageSize, name, email, type, status, startDate, endDate);
        yield put(setLoosePayments(data.payments));
        yield put(setTotal(data.total));

    } catch (error) {
        handleError(error);
    } finally {
        yield put(setIsLoading(false));
    };
};

function* requestLoosePaymentsGenerateReport({ payload }: any) {
    const { name, email, type, status, startDate, endDate } = payload;

    function downloadXlsx(base64Data: string) {
        const link = document.createElement('a');
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Data}`;
        link.download = 'PagamentosAvulsos.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    try {
        yield put(setLoadingReport(true));
        const data = yield call(loosePaymentsFilterDownloadXlsx, name, email, type, status, startDate, endDate);
        downloadXlsx(data)
    } catch (error) {
        handleError(error);
    } finally {
        yield put(setLoadingReport(false));
    };
}

function* resendEmail({ payload }: any) {
    try {
        yield call(resendLoosePaymentEmail, payload.id);
        Notification(NOTIFICATION_TYPE.SUCCESS, 'E-mail enviado', 'E-mail enviado com sucesso!');

        yield put(updatePaymentStatus(payload.id, 'SENT'));
    } catch (error) {
        handleError(error);
    } finally {
        if (payload.callback) {
            payload.callback();
        }
    };
}

export default function* MySaga(): any {
    yield all([
        yield takeEvery(LoosePaymentActionTypes.ASYNC_GET_LOOSE_PAYMENTS, requestLoosePayments),
        yield takeEvery(LoosePaymentActionTypes.ASYNC_GET_COACH_BY_EMAIL, requestGetCoachByEmail),
        yield takeEvery(LoosePaymentActionTypes.ASYNC_POST_LOOSE_PAYMENT, requestPostLoosePayment),
        yield takeEvery(LoosePaymentActionTypes.ASYNC_GET_CODE, requestGetCode),
        yield takeEvery(LoosePaymentActionTypes.ASYNC_GET_FILTER_PEYMENT, requestLoosePaymentsFilter),
        yield takeEvery(LoosePaymentActionTypes.ASYNC_GET_LOOSE_PAYMENT_GENERATE_REPORT, requestLoosePaymentsGenerateReport),
        yield takeEvery(LoosePaymentActionTypes.ASYNC_RESEND_EMAIL, resendEmail),
    ]);
};
