import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button } from "antd";

import Search from "antd/lib/input/Search";
import { IState } from "../../../../reducers/reducers";
import { asyncGetBanners, setCurrentPage, setPageList, setSearch } from "../../../../reducers/banners.reducer";

const SearchFilter = () => {
    const { search } = useSelector((state: IState) => state.banners);
    const dispatch = useDispatch();

    const onSearch = () => {
        dispatch(setCurrentPage(0));
        dispatch(setPageList([undefined]))
        dispatch(asyncGetBanners());
    };

    return (
        <Form className="filter-content3">
            <div className="input-filter">
                <label>Buscar</label>
                <Search
                    value={search}
                    className="search-input"
                    placeholder="Buscar"
                    onSearch={onSearch}
                    onChange={({ target }) =>
                        dispatch(setSearch(target.value))
                    }
                />
            </div>

            <Button
                onClick={onSearch}
                className="btn-search"
                type="primary"
                icon="search"
            >
                Pesquisar
            </Button>
        </Form>
    );
};

export default SearchFilter;
