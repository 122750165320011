/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useCallback } from 'react';
import { Form, Button, Select } from 'antd';
import Search from 'antd/lib/input/Search';
import { useDispatch } from 'react-redux';
import './SearchFilter.scss';
import { setPage } from '../../../reducers/loose-payment.reducer';
import MaskedDatePicker from '../../../components/masked-date-picker/masked-date-picker';
import moment from 'moment-timezone';

interface IProps {
    isLoading: boolean,
    name: string,
    email: string,
    from?: string | null;
    to?: string | null;
    status?: string;
    type?: string;
    loadingReport: boolean,
    search: (payload: object) => void,
    setName: (name: string) => void,
    setEmail: (email: string) => void,
    onChangeFilter: (key: string, value?: string | null) => void;
    setStatus: (status: string) => void;
    setType: (type: string) => void;
    generateLoosePaymentsReport: (payload: object) => void,
};

const { Option } = Select;

const StatusOptions: { label: string, value?: string }[] = [
    { label: 'Todos', value: '' },
    { label: 'Criado', value: 'CREATED' },
    { label: 'Enviado', value: 'SENT' },
    { label: 'Expirado', value: 'EXPIRED' },
    { label: 'Pago', value: 'PAID' },
]

const TypesOptions: { label: string, value?: string }[] = [
    { label: 'Todos', value: '' },
    { label: 'Recorrente', value: 'RECORRENTE' },
    { label: 'Anual', value: 'ANUAL' },
    { label: 'Automático', value: 'AUTOMATIC' },
]

const LoosePaymentFilter = ({
    isLoading,
    name,
    email,
    from,
    to,
    status,
    loadingReport,
    type,
    search,
    setName,
    setEmail,
    onChangeFilter,
    setStatus,
    setType,
    generateLoosePaymentsReport,
}: IProps) => {
    const dispatch = useDispatch();

    const searchData = () => {
        dispatch(setPage(1));
        search({});
    };

    const cleanFrom = useCallback(() => onChangeFilter('from', null), [onChangeFilter]);
    const cleanTo = useCallback(() => onChangeFilter('to', ''), [onChangeFilter]);

    const changeFrom = useCallback((_: string, from: string) => onChangeFilter('from', moment(from).toISOString()), [onChangeFilter]);
    const changeTo = useCallback((_: string, to: string) => onChangeFilter('to', moment(to).endOf('day').toISOString()), [onChangeFilter]);

    return (
        <Form className='loose-payment-filter'>
            <div className='input-filter'>
                <label>Buscar por nome</label>
                <Search
                    value={name}
                    className='search-input'
                    placeholder='Nome'
                    onSearch={searchData}
                    onChange={({ target }) => setName(target.value)} />
            </div>
            <div className='input-filter'>
                <label>Buscar por e-mail</label>
                <Search
                    value={email}
                    className='search-input'
                    placeholder='E-mail'
                    onSearch={searchData}
                    onChange={({ target }) => setEmail(target.value)} />
            </div>
            <div className="input-filter">
                <label>Filtrar por tipo</label>
                <Select
                    mode="default"
                    placeholder="Todos"
                    maxTagCount={1}
                    style={{ width: 160 }}
                    value={type || undefined}
                    onChange={setType}>

                    {TypesOptions.map(({ label, value }) => (
                        <Option key={value} value={value}>
                            {label}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className="input-filter">
                <label>Filtrar por status</label>
                <Select
                    mode="default"
                    placeholder="Todos"
                    maxTagCount={1}
                    style={{ width: 140 }}
                    value={status || undefined}
                    onChange={setStatus}>

                    {StatusOptions.map(({ label, value }) => (
                        <Option key={value} value={value}>
                            {label}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className="div-input-date">
                <span>Início:</span>
                <MaskedDatePicker
                    onChange={changeFrom}
                    currentValue={from || ''}
                    stringType={'enrollment_date'}
                    classText="input-date"
                    placeholder="Data"
                    onClean={cleanFrom}
                    forcePeriodOfDay='START'
                />
            </div>
            <div className="div-input-date" >
                <span>Fim:</span>
                <MaskedDatePicker
                    onChange={changeTo}
                    currentValue={to || ''}
                    stringType={'enrollment_date'}
                    classText="input-date"
                    placeholder="Data"
                    onClean={cleanTo}
                    forcePeriodOfDay='END'
                />
            </div>
            <Button
                onClick={searchData}
                className='btn-search'
                type='primary'
                disabled={isLoading || loadingReport}
                icon='search'>
                Buscar
            </Button>
            <Button
                onClick={generateLoosePaymentsReport}
                className='btn-download-report'
                type='primary'
                disabled={isLoading || loadingReport}
                icon='file'>
                Baixar Relatório
            </Button>
        </Form>
    )
};

export default LoosePaymentFilter;
