import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { IQuerySales } from "../models/sales-sf.models";
import { IState } from "../reducers/reducers";
import { listAllSales } from "../utils/webApi";
import { asyncSetSales, asyncSetTotal, SalesSFActionTypes, setLoading } from "../reducers/sales-sf.reducer";
import handleError from "../utils/handleError";

export function* getAllSalesSF({payload}: any) {
    try {
        yield put(setLoading(true));
        const {
            from,
            to,
            search,
            status,
        } = yield select((state: IState) => state.salesSF);

        const { total, data } = yield call(
            listAllSales,
            { page: payload.page, pageSize: payload.pageSize, from, to, search, status } as IQuerySales
        )

        yield put(asyncSetSales(data));
        yield put(asyncSetTotal(total));

        if (payload.callback) {
            payload.callback({ data, total });
        }

    } catch (error) {
        handleError(error);
    } finally {
        yield put(setLoading(false));
    }
}

export default function* MySaga(): any {
    yield all([
        yield takeEvery(SalesSFActionTypes.ASYNC_GET_SALES_SF, getAllSalesSF),
    ]);
}