import React, { useState } from 'react';
import { Button, Icon, message, Pagination, Table, Tooltip } from 'antd';
import { formatDateUtc } from '../../../utils/moment.functions';
import { ILoosePayment } from '../../../models/loose-payment.model';

import './PaymentList.scss';
import Text from 'antd/lib/typography/Text';
import { setPage, setPageSize, asyncResendEmail, updatePaymentStatus } from '../../../reducers/loose-payment.reducer';
import { useDispatch } from 'react-redux';
import { urlAssine } from '../../../utils/urls.constants';
import { history } from '../../../config/Store.config';
import { routes } from '../../../utils/routers.constants';

interface IProps {
    payments: ILoosePayment[],
    isLoading: boolean,
    total: number,
    page: number,
    pageSize: number,
};

const PaymentList = ({
    payments,
    isLoading,
    total,
    page,
    pageSize,
}: IProps) => {
    const [sends, setSends] = useState<string[]>([]);
    const dispatch = useDispatch();

    const translateStatus = (status: string) => {
        if (status === 'CREATED') {
            return 'Criado'
        }
        if (status === 'PAID') {
            return 'Pago'
        }
        if (status === 'EXPIRED') {
            return 'Expirado'
        }
        if (status === 'SENT') {
            return 'Enviado'
        }
    };

    const columns = [
        {
            title: 'Nome Coach',
            dataIndex: 'nickname',
            key: 'nickname',
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Código',
            dataIndex: 'id',
            key: 'id',
            render: (id: any) => <CopyLinkToClipboardButton id={id} />
        },
        {
            title: 'Tipo',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            key: 'value',
            render: (value: number) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value/100)
        },
        {
            title: 'Criado em',
            dataIndex: 'date_created',
            key: 'date_created',
            render: (text: any) => formatDateUtc(text, 'DD/MM/YYYY [às] HH:mm:ss')
        },
        {
            title: 'Pago em',
            dataIndex: 'transaction_data',
            key: 'transaction_data',
            render: (transactionData: any) => transactionData !== null && receivedDate(transactionData)
        },
        {
            title: 'Data Expiração',
            dataIndex: 'date_expiration',
            key: 'date_expiration',
            render: (text: any) => formatDateUtc(text)
        },
        {
            title: 'Geração Automatica',
            dataIndex: 'is_automatic',
            key: 'is_automatic',
            render: (automatic: any) => automatic ? 'SIM' : 'NÃO'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: any) => <span className={`loose-status ${status}`}>{translateStatus(status)}</span>
        },
        {
            title: 'Ações',
            key: 'action',
            width: 140,
            render: (payment:ILoosePayment) => {
                const id = payment.id as string;
                const disabled = sends.includes(id as never);
                return (
                    <span className='icons-action'>
                        {
                            payment.status && ['SENT', 'CREATED'].includes(payment.status) && (
                                <Tooltip title='Enviar e-mail'>
                                    <Icon
                                        type={disabled ? 'loading' : 'mail'}
                                        onClick={() => disabled ? undefined : resendEmail(id)}
                                    />
                                </Tooltip>
                            )
                        }
                        {
                            <Tooltip title='Pagamentos'>
                                <Icon
                                    type='tool'
                                    onClick={() => history.push(routes.PAYMENTS(payment.user_id as string))}
                                />
                            </Tooltip>
                            
                        }
                    </span>
                )
            }
        },
    ];

    function changePageSize(pageSize: number) {
        dispatch(setPage(1));
        dispatch(setPageSize(pageSize));
    };

    function resendEmail(id: string) {
        setSends((prev) => [...prev, id]);
        dispatch(
            asyncResendEmail(id, () => {
                setSends((prev) => prev.filter((sendId) => sendId !== id));

                dispatch(updatePaymentStatus(id, 'SENT'));
            })
        );
    };

    function receivedDate(transactionData: any) {
        const paymentDate =  transactionData[0].dateCreated;
        return formatDateUtc(paymentDate, 'DD/MM/YYYY [às] HH:mm:ss');
    };

    return (
        <>
            <Table
                loading={isLoading}
                columns={columns}
                className={'table-payments'}
                rowClassName = {(payment: ILoosePayment) => payment.code ? 'row-canceled-payments' : 'row-payments' }
                dataSource={payments.map((payment: ILoosePayment) => ({ ...payment, key: payment.id }))}
                pagination={false}
            />
            <div style={{ textAlign: 'right', marginTop: 10, marginBottom: -15 }}>
                <Text><b>{total}</b> pagamentos encontrados</Text>
            </div>
            <Pagination
                className='all-payments-pagination'
                showSizeChanger
                defaultCurrent={page}
                current={page}
                defaultPageSize={pageSize}
                total={total}
                pageSizeOptions={['10', '25', '50', '100']}
                onChange={page => dispatch(setPage(page))}
                onShowSizeChange={(_, pageSize) => changePageSize(pageSize)}
            />
        </>
    );
};

function CopyLinkToClipboardButton({id}: {id: string}) {
    return (
        <div
            style={{
                margin: '0 auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                maxWidth: '300px',
            }}
        >
            <span>
                {id}
            </span>
            <Tooltip title='Copiar link para pagamento'>
                <Button onClick={() => handleCopyLink(id)} type='primary'>
                    <Icon type='copy' />
                </Button>
            </Tooltip>
        </div>
    );
}

function handleCopyLink(id: string) {
    navigator.clipboard.writeText(`${urlAssine}/pagamento/${id}`).then(() => {
        message.success(
            <span style={{color: '#222'}}>
                Link copiado!
            </span>
        );
    })
}

export default PaymentList;
