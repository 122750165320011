export interface IBanner {
    id: string,
    viewType: string,
    isEnabled: boolean,
    titlePt: string,
    imageUrlPt: string,
    linkPt?: string,
    titleEn?: string,
    imageUrlEn?: string,
    linkEn?: string,
    titleEs?: string,
    imageUrlEs?: string,
    linkEs?: string,
    expirationDate: string,
    createdAt: string,
    updatedAt: string,
    entity?: string,
    search?: string,
    status?: string,
    key?: string,
    isSwitchChange?: boolean,
};

interface IProperties {
    title: string,
    thumbnailUrl: string,
    link?: string,
}

export interface IBannerEdit {
    id: string,
    viewType: string,
    isEnabled: boolean,
    expirationDate: string,
    createdAt: string,
    updatedAt: string,
    entity?: string,
    search?: string,
    status?: string,
    key?: string,
    properties_pt: IProperties,
    properties_es: Partial<IProperties>,
    properties_en: Partial<IProperties>,
    isSwitchChange?: boolean,
};

export type ILangPropsKeys = 'properties_pt' | 'properties_es' | 'properties_en';

export interface IQueryStringList {
    pageSize?: number,
    search?: string,
    nextPage?: string,
    orderBy?: IOrderByBanner,
};

export type IOrderByBanner = 'ASC' | 'DESC';

export const queryStringListBanners = ({
    pageSize,
    search,
    nextPage,
    orderBy,
}: IQueryStringList) => {
    let params = [];

    if (search) {
        params.push(`search=${window.encodeURIComponent(search)}`);
    }

    if (pageSize) {
        params.push(`pageSize=${pageSize}`);
    }

    if (orderBy) {
        params.push(`orderBy=${orderBy}`);
    }

    if (nextPage) {
        params.push(`nextPage=${nextPage}`);
    }

    return `?${params.join("&")}`;
};
