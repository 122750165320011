import React from 'react';
import { Button, Modal } from 'antd';
import { ISaleDetail } from '../../../models/sales-sf.models';
import Input from '../../../components/Input/Input';
import './SalesEdit.scss';
import Loading from '../../../components/loading/Loading';

interface SalesEditProps {
    onChange(name: string, value: any): void;
    onOk(orderId: string, resend?: boolean): void;
    onClose: () => void;
    sale: ISaleDetail;
    loading: boolean;
    editLoading: boolean;
}

const SalesEdit: React.FC<SalesEditProps> = ({
    onChange,
    onOk,
    onClose,
    sale,
    loading,
    editLoading,
}) => {
    const isCNPJ = sale.customer && sale.customer.document ? sale.customer.document.type === "CNPJ" : false;

    return (
        <Modal
            className='modal-sales-edit-sf'
            title="Detalhes da Venda"
            visible={true}
            onCancel={onClose}
            footer={null}
            destroyOnClose={true}
            width={600}
        >
            {
                editLoading
                    ? <Loading />
                    : (
                        <>
                            <form className='grid-info-sales-sf'>
                                {
                                    isCNPJ && (
                                        <>
                                            <div>
                                                <span>Nome da Corporação</span>
                                                <Input
                                                    placeholder='Nome da Corporação'
                                                    value={sale.customer.corporateName}
                                                    onChange={({ target }) => onChange('corporateName', target.value)} />
                                            </div>

                                            <div>
                                                <span>Nome Comercial</span>
                                                <Input
                                                    placeholder='Nome Comercial'
                                                    value={sale.customer.doingBusiness}
                                                    onChange={({ target }) => onChange('doingBusiness', target.value)} />
                                            </div>
                                        </>
                                    )
                                }
                                <div>
                                    <span>Primeiro Nome</span>
                                    <Input
                                        placeholder='Primeiro Nome'
                                        value={sale.customer.firstName}
                                        onChange={({ target }) => onChange('firstName', target.value)} />
                                </div>
                                <div>
                                    <span>Último Nome</span>
                                    <Input
                                        placeholder='Último Nome'
                                        value={sale.customer.lastName}
                                        onChange={({ target }) => onChange('lastName', target.value)} />
                                </div>

                                <div>
                                    <span>Email</span>
                                    <Input
                                        placeholder='Email'
                                        value={sale.customer.email}
                                        onChange={({ target }) => onChange('email', target.value)} />
                                </div>

                                <div>
                                    <span>{isCNPJ ? 'CNPJ' : 'CPF'}</span>
                                    <Input
                                        placeholder={isCNPJ ? 'CNPJ' : 'CPF'}
                                        value={sale.customer.document.number}
                                        onChange={({ target }) => onChange('document.number', target.value)} />
                                </div>

                                <div>
                                    <span>Estado</span>
                                    <Input
                                        placeholder='Estado'
                                        value={sale.customer.address.state}
                                        onChange={({ target }) => onChange('address.state', target.value)} />
                                </div>

                                <div>
                                    <span>Cidade</span>
                                    <Input
                                        placeholder='Cidade'
                                        value={sale.customer.address.city}
                                        onChange={({ target }) => onChange('address.city', target.value)} />
                                </div>

                                <div>
                                    <span>Bairro</span>
                                    <Input
                                        placeholder='Bairro'
                                        value={sale.customer.address.district}
                                        onChange={({ target }) => onChange('address.district', target.value)} />
                                </div>

                                <div>
                                    <span>Rua</span>
                                    <Input
                                        placeholder='Rua'
                                        value={sale.customer.address.street}
                                        onChange={({ target }) => onChange('address.street', target.value)} />
                                </div>

                                <div>
                                    <span>Número</span>
                                    <Input
                                        placeholder='Número'
                                        value={sale.customer.address.number}
                                        onChange={({ target }) => onChange('address.number', target.value)} />
                                </div>

                                <div>
                                    <span>País</span>
                                    <Input
                                        placeholder='País'
                                        value={sale.customer.address.country}
                                        onChange={({ target }) => onChange('address.country', target.value)} />
                                </div>

                                <div>
                                    <span>CEP</span>
                                    <Input
                                        placeholder='CEP'
                                        value={sale.customer.address.postalCode}
                                        onChange={({ target }) => onChange('address.postalCode', target.value)} />
                                </div>

                                <div>
                                    <span>Telefone</span>
                                    <Input
                                        placeholder='Telefone'
                                        value={sale.customer.phone}
                                        onChange={({ target }) => onChange('phone', target.value)} />
                                </div>
                            </form><div className='buttons-bottom-sales-sf'>
                                <Button
                                    className="btn-cancel"
                                    onClick={onClose}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    disabled={loading}
                                    type="primary"
                                    onClick={() => onOk(sale.orderId)}
                                >
                                    {loading ? "Atualizando..." : "Atualizar"}
                                </Button>
                                <Button
                                    disabled={loading}
                                    type="primary"
                                    onClick={() => onOk(sale.orderId, true)}
                                >
                                    {loading ? "Atualizando..." : "Reenviar"}
                                </Button>
                            </div>
                        </>
                    )
            }
        </Modal>
    );
};

export default SalesEdit;
