import React, { useState } from 'react';
import {Input, DatePicker, Icon } from 'antd';
import {ISOFromMoment, momentFromISO, formatDate, maskDatePicker } from '../../utils/moment.functions'
import moment from 'moment-timezone';

import './masked-date-picker.scss';

interface IMaskedDateInput {
    onChange: Function,
    currentValue?: any,
    stringType: string,
    placeholder: string,
    classText?: string,
    disabled?: boolean,
    onClean?: Function,
    minDate?: string,
    forcePeriodOfDay?: 'START' | 'END',
};

const MaskedDateInput = ({
    onChange,
    currentValue,
    stringType,
    placeholder,
    classText,
    onClean,
    disabled,
    minDate,
    forcePeriodOfDay,
}: IMaskedDateInput) => {
    const [ value, setValue ] = useState(currentValue ? formatDate(currentValue) : '');
    const handleClean = () => {
        if(onClean) {
            onClean();
            setValue('');
        }
    };

    function handleInputChange({target}: any){
        let newDate = maskDatePicker(target.value);
        setValue(newDate);
        if (moment(newDate, 'DD/MM/YYYY').isValid()) {
            if (forcePeriodOfDay) {
                onChange(
                    stringType,
                    moment(newDate, 'DD/MM/YYYY')[forcePeriodOfDay === 'START' ? 'startOf' : 'endOf']('day').toISOString()
                );
            } else {
                onChange(stringType, moment(newDate, 'DD/MM/YYYY').toISOString());
            }
        }
    }

    function handleDatePicker(moment: any, dateString: string){
        setValue(dateString);
        if(moment){
            if (forcePeriodOfDay) {
                onChange(stringType, ISOFromMoment(moment[forcePeriodOfDay === 'START' ? 'startOf' : 'endOf']('day')));
            } else {
                onChange(stringType, ISOFromMoment(moment));
            }
        } else {
            onChange(stringType, null);
        }
    }
    
    return (
        <>
            <Input
                className={classText ? "input-masked-date-"+classText : "input-masked-date" }
                value={value}
                disabled={disabled || false}
                placeholder={ placeholder }
                onChange={handleInputChange}
            />
        {(onClean && value) && <Icon type='close-circle' className="date-picker-clean-icon" onClick={handleClean}/>}
            <DatePicker
                disabledDate={d => minDate ? !d || moment(d).isBefore(minDate) : false}
                className={classText ? "date-picker-masked-date-"+classText : "date-picker-masked-date" }
                placeholder={ placeholder }
                format="DD/MM/YYYY"
                disabled={disabled || false}
                defaultValue={currentValue ? momentFromISO(currentValue) : undefined}
                onChange={handleDatePicker}
            />
        </>
    )
};

export default MaskedDateInput;
