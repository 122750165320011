import React from 'react';
import { Form, Button, Select } from 'antd';
import Search from 'antd/lib/input/Search';

import { useDispatch } from 'react-redux';
import './SearchFilter.scss';
import { changeFromDate, changeSearchValue, changeStatusValue, changeToDate } from '../../../reducers/sales-sf.reducer';
import { SaleStatus } from '../../../models/sales-sf.models';
import MaskedDatePicker from '../../../components/masked-date-picker/masked-date-picker';
import moment from 'moment-timezone';

const { Option } = Select;

const fieldsOptions: { label: string, value: SaleStatus }[] = [
    { label: 'Todos', value: SaleStatus.ALL },
    { label: 'Pendente', value: SaleStatus.PENDING },
    { label: 'Criado', value: SaleStatus.CREATED },
    { label: 'Cancelado', value: SaleStatus.CANCELED },
];

const SearchFilter = ({
    search,
    status,
    from,
    to,
    onSearch
}: {
    search: string;
    status: string;
    from: string;
    to: string;
    onSearch: (
        search: string,
        status: string,
        from: string,
        to: string,
    ) => void
}) => {
    const dispatch = useDispatch();

    const handleFilter = (search: string, status: string, from: string, to: string) => {
        if(from && to) {
            from = moment(from).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
            to = moment(to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
        }
        
        dispatch(changeSearchValue(search));
        if (status !== 'ALL') {
            dispatch(changeStatusValue(status.toString()));
        } else {
            dispatch(changeStatusValue(''));
        }
        dispatch(changeFromDate(from));
        dispatch(changeToDate(to));
        
        onSearch(search, status.toString(), from, to);
    }

    return (
        <Form className='filter-content2'>
            <div className='input-filter'>
                <label>Buscar</label>
                <Search
                    value={search && search.length > 0 ? search : undefined}
                    className='search-input'
                    placeholder='Buscar'
                    onSearch={() => { handleFilter(search, status, from, to) }}
                    onChange={({ target }) => dispatch(changeSearchValue(target.value))}
                />
            </div>

            <div className='input-filter'>
                <label>Status</label>
                <Select
                    placeholder='Todos'
                    maxTagCount={1}
                    allowClear={true}
                    value={status && status.length > 0 ? status : undefined}
                    onSearch={() => { handleFilter(search, status, from, to) }}
                    onChange={(status: string) => dispatch(changeStatusValue(status))}
                >
                    {fieldsOptions.map(({ label, value }) => (
                        <Option key={value} value={value}>
                            {label}
                        </Option>
                    ))}
                </Select>
            </div>

            <div className="div-input-date" >
                <span>Início:</span>
                <MaskedDatePicker
                    onChange={(_: any, from: string) => dispatch(changeFromDate(from))}
                    currentValue={from || ''}
                    stringType={'from_date'}
                    classText="input-date"
                    placeholder=""
                    onClean={() => dispatch(changeFromDate(''))}
                />
            </div>
            <div className="div-input-date" >
                <span>Fim:</span>
                <MaskedDatePicker
                    onChange={(_: any, to: string) => dispatch(changeToDate(to))}
                    currentValue={to || ''}
                    stringType={'to_date'}
                    classText="input-date"
                    placeholder=""
                    onClean={() => dispatch(changeToDate(''))}
                />
            </div>

            <Button
                onClick={() => handleFilter(search, status, from, to)}
                className='btn-search'
                type='primary'
                icon='search'
            >
                Pesquisar
            </Button>
        </Form>
    )
};

export default SearchFilter;
