import { Button, Icon, message, Pagination, Switch, Table, Tooltip } from "antd";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IState } from "../../../reducers/reducers";
import { formatDateWithHours } from "../../../utils/moment.functions";
import { IBanner, IOrderByBanner } from "../../../models/banner.model";
import { asyncEditBanner, setBannerModal, setCurrentPage, setOrderBy } from "../../../reducers/banners.reducer";

import SearchFilter from "./search-filter/SearchFilter";

import "./BannersList.scss";

interface IBannerListProps {
    banners: IBanner[],
    loading: boolean,
    getBanners(): void,
    onEdit(banner: IBanner): void,
};

const BannersList = ({banners, loading, getBanners}: IBannerListProps) => {
    const { page, pageSize, pageList } = useSelector(({banners}: IState) => ({
        page: banners.page,
        pageSize: banners.pageSize,
        pageList: banners.pageList,
    }));

    const totalPages = useMemo<number>(() => {
        const hasNextPage = Boolean(pageList[page + 1]);

        return (page + (hasNextPage ? 2 : 1)) * pageSize;
     // eslint-disable-next-line
    }, [page, pageList]);

    const dispatch = useDispatch();

    useEffect(() => {
        getBanners();
        // eslint-disable-next-line
    }, [page, pageSize, dispatch]);

    function handleSwitchChange(banner: IBanner) {
        dispatch(asyncEditBanner({
            ...banner,
            isEnabled: !banner.isEnabled,
            isSwitchChange: true,
        }));
    };

    const columns = [
        {
            title: "Id",
            key: "id",
            dataIndex: 'id',
        },
        {
            title: "Título",
            key: "titlePt",
            width: 350,
            render: (banner: IBanner) => {
                const title = banner.titlePt;
                return title;
            },
        },
        {
            title: "Imagem",
            key: "imageUrlPt",
            width: 150,
            render: (banner: IBanner) => <img className="thumb" src={banner.imageUrlPt} alt="banner" />
        },
        {
            title: 'Data de criação',
            key: 'createdAt',
            width: 200,
            render: (banner: IBanner) => {
                const dateTime = banner.createdAt;
                return formatDateWithHours(dateTime);
            },
        },
        {
            title: 'Data de expiração',
            key: 'expirationDate',
            render: (banner: IBanner) => {
                const dateTime = banner.expirationDate;
                return formatDateWithHours(dateTime);
            },
        },
        {
            title: "Modo de exibição",
            key: "viewType",
            render: (banner: IBanner) => {
                const viewType = banner.viewType === 'SINGLE_VIEW' ? 'Só uma vez' : 'Até cancelar';
                return viewType;
            },
        },
        {
            title: "Link",
            key: "linkPt",
            width: 280,
            render: (banner: IBanner) => banner.linkPt && <CopyLinkToClipboardButton link={banner.linkPt} />
        },
        {
            title: "Ações",
            key: "action",
            width: 100,
            render: (banner: IBanner) => (
                <span className="icons-action">
                    <Tooltip title="Editar banner">
                        <Icon
                            type="edit"
                            onClick={() => {
                                dispatch(setBannerModal(banner));
                            }}
                        />
                    </Tooltip>
                    <Tooltip title={banner.isEnabled ? "Desabilitar banner" : "Habilitar banner"}>
                        <Switch
                            checked={banner.isEnabled}
                            onChange={() => handleSwitchChange(banner)}
                        />
                    </Tooltip>
                </span>
            ),
        },
    ];

    return (
        <>
            <SearchFilter />
            <Table
                className="banner-list"
                pagination={false}
                loading={loading}
                columns={columns}
                dataSource={banners.map((banner: IBanner) => ({
                    ...banner,
                    key: banner.id,
                }))}
                sortDirections={["ascend", "descend", "ascend"]}
                onChange={(c1, c2, sorter) => dispatch(
                    setOrderBy(sorter.order.replace("end", "").toUpperCase() as IOrderByBanner)
                )}
            />
            <Pagination
                className="pagination"
                pageSize={pageSize}
                total={totalPages}
                current={page + 1}
                onChange={(nextPage) => dispatch(setCurrentPage(nextPage - 1))}
                style={{ marginTop: "25px" }}
            />
        </>
    )
};

function CopyLinkToClipboardButton({link}: {link: string}) {
    return (
        <div
            style={{
                margin: '0 auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                maxWidth: '300px',
            }}
        >
            <span style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            }}>
                {link}
            </span>
            <Tooltip title={link}>
                <Button onClick={() => handleCopyLink(link)} type='primary'>
                    <Icon type='copy' />
                </Button>
            </Tooltip>
        </div>
    );
}

function handleCopyLink(link: string) {
    navigator.clipboard.writeText(link).then(() => {
        message.success(
            <span style={{color: '#222'}}>
                Link copiado!
            </span>
        );
    })
}

export default BannersList;
