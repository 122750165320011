import { IBanner, IOrderByBanner } from "../models/banner.model";

// Types
export enum BannersActionTypes {
    ASYNC_GET_BANNERS = "@banners/ASYNC_GET_BANNERS",
    ASYNC_CREATE_BANNER = "@banners/ASYNC_CREATE_BANNER",
    ASYNC_EDIT_BANNER = "banners/ASYNC_EDIT_BANNER",

    SET_BANNERS = "@banners/SET_BANNERS",
    SET_LOADING = "@banners/SET_LOADING",
    SET_SAVE_LOADING = "@banners/SET_SAVE_LOADING",
    SET_BANNER_MODAL = "@banners/SET_BANNER_MODAL",
    SET_CURRENT_PAGE = "@banners/SET_CURRENT_PAGE",
    SET_PAGE_LIST = "@banners/SET_PAGELIST",
    SET_ORDER_BY = "@banners/SET_ORDER_BY",
    SET_SEARCH = "@banners/SET_SEARCH",
};

// Interfaces
export interface IBannersStore {
    banners: IBanner[],
    bannerModal: Partial<IBanner> | null,
    page: number,
    pageSize: number,
    pageList: string[] | undefined[] | null[],
    search: string,
    orderBy: IOrderByBanner,
    isLoadingSave: boolean,
    isLoading: boolean,
};

export interface BannersAction {
    type: BannersActionTypes,
    payload: any,
};

// Reducer
const INITIAL_STATE: IBannersStore = {
    banners: [],
    bannerModal: null,
    page: 0,
    pageList: [undefined],
    pageSize: 10,
    search: '',
    orderBy: 'DESC',
    isLoadingSave: false,
    isLoading: false,

};

export const reducer = (
    state: IBannersStore = INITIAL_STATE,
    action: BannersAction,
): IBannersStore => {
    const {type, payload} = action;

    switch(type) {
        case BannersActionTypes.SET_BANNERS:
            return {...state, banners: payload};
        case BannersActionTypes.SET_LOADING:
            return {...state, isLoading: payload};
        case BannersActionTypes.SET_SAVE_LOADING:
            return {...state, isLoadingSave: payload};
        case BannersActionTypes.SET_BANNER_MODAL:
            return {...state, bannerModal: payload};
        case BannersActionTypes.SET_SEARCH:
            return {...state, search: payload};
        case BannersActionTypes.SET_CURRENT_PAGE:
            return {...state, page: payload};
        case BannersActionTypes.SET_PAGE_LIST:
            return {...state, pageList: payload};
        case BannersActionTypes.SET_ORDER_BY:
            return {...state, orderBy: payload};
        default:
            return state;
    };
};

//Actions
export const asyncGetBanners = () => ({
    type: BannersActionTypes.ASYNC_GET_BANNERS,
});

export const asyncCreateBanner = (payload: IBanner) => ({
    type: BannersActionTypes.ASYNC_CREATE_BANNER,
    payload,
});

export const asyncEditBanner = (payload: IBanner) => ({
    type: BannersActionTypes.ASYNC_EDIT_BANNER,
    payload,
});

export const setBanners = (banners: IBanner[]) => ({
    type: BannersActionTypes.SET_BANNERS,
    payload: banners
});

export const setPageList = (pageList: string[] | undefined[] | null[]) => ({
    type: BannersActionTypes.SET_PAGE_LIST,
    payload: pageList,
});

export const setCurrentPage = (page: number) => ({
    type: BannersActionTypes.SET_CURRENT_PAGE,
    payload: page
});

export const setSearch = (payload: string) => ({
    type: BannersActionTypes.SET_SEARCH,
    payload,
});

export const setOrderBy = (payload: IOrderByBanner) => ({
    type: BannersActionTypes.SET_ORDER_BY,
    payload,
});

export const setLoading = (value: boolean) => ({
    type: BannersActionTypes.SET_LOADING,
    payload: value
});

export const setSaveLoading = (value: boolean) => ({
    type: BannersActionTypes.SET_SAVE_LOADING,
    payload: value
});

export const setBannerModal = (banner: Partial<IBanner> | null) => ({
    type: BannersActionTypes.SET_BANNER_MODAL,
    payload: banner
});

export default reducer;
