import { Button, Icon, Upload } from "antd";
import { RcCustomRequestOptions } from "antd/lib/upload/interface";
import React, { useState } from "react";
import { IVideo } from "../../models/video.model";
import { getBase64 } from "../../utils/utils.functions";
import { getPostUrls, uploadFile, getFebracisUploadUrls } from "../../utils/webApi";

interface IPropsUploadImage {
    isEdit: boolean,
    currentState: any,
    setCurrentState: Function,
    types: string,
    uploadTo?: 'febracis-upload' | 'febracis-resources'
    uploadImageMessage?: string,
    removeImageMessage?: string,
};

const ImageUpload = ({
    isEdit,
    currentState,
    setCurrentState,
    types,
    uploadTo = 'febracis-resources',
    uploadImageMessage = 'Escolha uma Thumbnail',
    removeImageMessage = 'Remover imagem',
}: IPropsUploadImage) => {

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(currentState[types].thumbnailUrl || null);

    const handleChange = async (info: any) => {
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }
        if (info.file.status === "done") {
                await getBase64(info.file.originFileObj, (url: string) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };

    const handleRequest = async ({file, onSuccess}: RcCustomRequestOptions) => {
        let urls = {
            get: '',
            put: '',
        };

        if (uploadTo === 'febracis-resources') {
            const data = {
                mime: file.type,
            };

            urls = await getPostUrls(data);
        } else {
            const data = {
                mime: file.type,
                name: file.name,
                path: 'banners',
            };

            urls = await getFebracisUploadUrls(data);
        }

        await uploadFile(urls.put, file, file.type);
        onSuccess({name: file.name, status: "done", url: urls.get, thumbUrl: urls.get}, file);

        let item = {
            ...currentState,
            [types]: {
                ...currentState[types],
            },
        };
        item[types].thumbnailUrl = urls.get;

        setCurrentState(item as IVideo);
    };

    function clearImage() {
        setImageUrl(null);
        let item = {...currentState};
        item = {...item, [types]: {...item[types], thumbnailUrl: null}};

        setCurrentState(item as IVideo);
    };

    return (
        <>
            <div className={"upload_container"}>
                <div className="image-upload" style={{display: "flex", marginLeft: "18px"}}>
                    <Upload
                        name="image-upload"
                        listType="picture-card"
                        showUploadList={false}
                        accept={"image/*"}
                        onChange={handleChange}
                        customRequest={handleRequest}
                    >
                        { !isEdit && imageUrl && <img src={imageUrl} alt={"img_Thumbnail"} style={{width: "100%", display: "flex", justifyContent: "center"}} /> }
                        {
                            (isEdit && currentState[types].thumbnailUrl) ?
                                <img src={currentState[types].thumbnailUrl} alt={"img_Thumbnail"} style={{width: "100%", display: "flex", justifyContent: "center"}} />
                                :
                                <div>
                                    {loading &&  <Icon type="loading" style={{ fontSize: 24, color: "black" }} spin /> }
                                    {!loading && !imageUrl &&
                                        <div>
                                            <Icon type="cloud-upload" style={{ fontSize: 24, color: "black" }} />
                                            <div style={{ color: "black", fontWeight: "bold" }}>
                                                {uploadImageMessage}
                                            </div>
                                        </div>
                                    }
                                </div>
                        }
                    </Upload>
                </div>
                {
                    (imageUrl || (isEdit && currentState[types].thumbnailUrl)) &&
                        <Button
                            className={"remove_btn"}
                            type="danger"
                            onClick={clearImage}
                        >
                            {removeImageMessage}
                        </Button>
                }
            </div>
        </>
    );
};

export default ImageUpload;
