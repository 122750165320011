import moment from 'moment-timezone';
import { ICategory } from '../models/categories.model';
import { IClass } from '../models/coache.model';

const timezone: string = 'America/Fortaleza';

export const formatSecondsToTime = (seconds: number) => {
    const duration = moment.duration(seconds, 'seconds');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const secs = duration.seconds();
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
}

export const formatDate = (date: any, format = 'DD/MM/YYYY'): string => {
    return moment.tz(date, timezone).format(format);
};

export const formatDateUtc = (date: any, format = 'DD/MM/YYYY'): string => {
    return moment.utc(date).tz(timezone).format(format);
};

export const formatDateWithHours = (date: any, format = 'DD/MM/YYYY [às] HH:mm:ss'): string => {
    return moment.tz(date, timezone).format(format);
};

export const ISOFromMoment = (date: moment.Moment): string => {
    return moment.tz(date, timezone).format();
};

export const momentFromISO = (date: string): moment.Moment => {
    return moment.tz(date, timezone);
};

export const setISOToDayStart = (date: string | null | undefined): string | null => {
    return moment.tz(date, timezone).set({ hour: 0, minute: 0, second: 0 }).format();
};

export const setISOToDayEnd = (date: string | null | undefined): string | null => {
    if (!date) return null;
    return moment.tz(date, timezone).set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).format();
};
export const maskDate = (value: string) => {
    let newValue = value.replace(/\D/g, '').slice(0, 10);
    if (newValue.length >= 5) {
        return `${newValue.slice(0, 2)}/${newValue.slice(2, 4)}/${newValue.slice(4)}`;
    }
    else if (newValue.length >= 3) {
        return `${newValue.slice(0, 2)}/${newValue.slice(2)}`;
    }
    return newValue
};

export const maskDatePicker = (value: string) => {
    const numbersOnly = value.replace(/\D/g, '');
    const day = numbersOnly.substring(0, 2);
    const month = numbersOnly.substring(2, 4);
    const year = numbersOnly.substring(4, 8);
    
    let formattedDate = day;
    if (month) formattedDate += '/' + month;
    if (year) formattedDate += '/' + year;
    
    return formattedDate;
};


export const validateDate = (currentDate: string) => {
    let date = currentDate;
    let day = parseInt(date.substring(0,2));
    let month = parseInt(date.substring(3,5));
    let year = parseInt(date.substring(6,10));

    let newDate = new Date(year,(month-1),day);

    let currentDay = day === newDate.getDate();
    let currentMonth = month === newDate.getMonth()+1;
    let currentYear = year === newDate.getFullYear();

    if (!((currentDay) && (currentMonth) && (currentYear) && (year.toString().length > 3))){
        return false;
    }
    return true;
};

export const findCategory = (parents: any, value: ICategory|string) => {
    const finded = parents.find((c:any) => c.id === value);
    if (finded) {
        return finded.name
    }
    return '--';
};

export const findClass = (values: IClass[], id: string|undefined) => {
    const finded = values.find((c:any) => c.id === id);
    if (finded) {
        return finded.className
    }
    return '--';
};
