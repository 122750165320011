import { Form, Input } from "antd";
import React from "react";

import "./PropertiesBanners.scss";
import { IBannerEdit, ILangPropsKeys } from "../../../models/banner.model";
import ImageUpload from "../../../components/image-upload/ImageUpload";

interface IProperties {
    currentEdit: IBannerEdit,
    setCurrentEdit: (banner: IBannerEdit) => void,
    isEdit: boolean,
    types: ILangPropsKeys,
    form: any,
};

const PropertiesBanners = ({types, currentEdit, setCurrentEdit, isEdit, form}: IProperties) => {
    const {getFieldDecorator} = form;

    function onChangeBanner(key: string, language: ILangPropsKeys, value: string) {
        let item = {...currentEdit};
        item = {...item, [language]: {...item[language], [key]: value}};

        setCurrentEdit(item as IBannerEdit);
    };

    return (
        <>
            <div className="group-input">
                <div className="input-group">
                    <Form.Item label='Nome do banner'>
                        {getFieldDecorator(`${types}.title`, {
                            rules: [
                                { required: types === 'properties_pt', message: 'Campo obrigatório' },
                                { min: 3, message: 'Título deve ter ao menos 3 caracteres' },
                                { max: 250, message: 'Título deve ter no máximo 250 caracteres' }
                            ],
                        })(
                            <Input
                                onChange={({ target }) => onChangeBanner("title", types, target.value)}
                                placeholder="Nome do banner"
                            />
                        )}
                    </Form.Item>
                    <Form.Item label='Link'>
                        {getFieldDecorator(`${types}.link`, {
                            rules: [
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        if (!value) {
                                            callback();
                                            return;
                                        }

                                        try {
                                            new URL(value);
                                            callback();
                                        } catch (e) {
                                            callback('Link deve ser uma URL válida');
                                        }
                                    },
                                },
                            ],
                        })(
                            <Input
                                onChange={({ target }) => onChangeBanner("link", types, target.value)}
                                placeholder="Link"
                            />
                        )}
                    </Form.Item>
                </div>
                <Form.Item label='Banner'>
                    {getFieldDecorator(`${types}.thumbnailUrl`, {
                        rules: [
                            { required: types === 'properties_pt', message: 'Campo obrigatório' },
                        ],
                    })(
                        <ImageUpload
                            isEdit={isEdit}
                            currentState={currentEdit}
                            setCurrentState={setCurrentEdit}
                            types={types}
                            uploadTo="febracis-upload"
                            uploadImageMessage="Escolha um Banner"
                            removeImageMessage="Remover Banner"
                        />
                    )}
                </Form.Item>
            </div>
        </>
    );
};

export default PropertiesBanners;
